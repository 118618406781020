<template>
    <section>
      <div class="w-full bg-white rounded-md mt-2 p-4">
        <div class="lg:flex">
          <div class="flex gap-2 items-center w-full lg:w-6/12">
            <i class="pi pi-list"></i>
            <p class="text-lg font-bold">Listado ordenes de venta</p>
          </div>
          <div v-if="$can('ut-segupharma.ventas.ov.crear')" class="w-full lg:w-6/12 flex lg:justify-end">
            <Button @click="$router.push({name: 'ut-segupharma.ventas.ov.crear'})" size="small" label="Crear orden de venta" class="w-full lg:w-max" icon="pi pi-plus" />
          </div>
        </div>
        <div class="grid grid-cold-1 md:grid-cols-2 lg:grid-cols-5 gap-2 my-2">
          <div class="w-full">
            <label class="text-xs text-gray-600" for="numero_orden">Número de orden</label>
            <InputText class="w-full " id="numero_orden" @keyup.enter="agregarOv" v-model="ordenSeleccionada" @keydown="validacionOrdenes($event)" />
            <div class="flex flex-wrap gap-1 mt-2 max-h-24 overflow-y-auto">
              <div v-for="(orden, i) in ordenesFiltradas" :key="i" class="">
                <div class="flex items-center p-1 bg-blue-200 text-blue-600 rounded-md">
                  <p class="text-xs">{{ orden }}</p>
                  <button @click="eliminarOrden(orden)" class="mx-1"><i class="pi pi-times text-xs"></i></button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full">
            <label class="text-xs text-gray-600" for="cliente">Cliente</label>
            <Dropdown
              id="cliente"
              panelClass="text-xs"
              class="w-full rounded-md"
              v-model="filtros.CardCode"
              :options="listadoClientes"
              optionLabel="CardName"
              optionValue="CardCode"
              :filter="true"
              :showClear="true">
            </Dropdown>
          </div>
          <div class="w-full">
            <label class="text-xs text-gray-600" for="fecha_inicio">Fecha inicio</label>
            <InputText
              id="fecha_inicio"
              type="date"
              class="w-full "
              v-model="filtros.fecha_inicio"
              :pt="{
                  root: { style: 'height: 37px' }
              }"
            />
          </div>
          <div class="w-full">
            <label class="text-xs text-gray-600" for="fecha_fin">Fecha fin</label>
            <InputText
              id="fecha_fin"
              type="date"
              class="w-full "
              v-model="filtros.fecha_fin"
              :pt="{
                  root: { style: 'height: 37px' }
              }"
            />
          </div>
          <div class="w-full">
            <label class="text-xs text-gray-600" for="bodega">Bodega</label>
            <AutoComplete
              id="bodega"
              class="w-full"
              inputClass="w-full"
              v-model="filtros.U_PHR_Bodega"
              :suggestions="bodegasFiltradas"
              @complete="buscarBodega($event)"
              field="name"
            />
          </div>
          <div class="w-full">
            <label class="text-xs text-gray-600">Identificación paciente</label>
            <div class="w-full flex gap-1">
              <Dropdown
                @change="filtros.U_PHR_NumDcto = ''"
                class="w-4/12 tipoId rounded-md"
                panelClass="text-xs"
                v-model="filtros.U_ACS_TpoIdentf"
                :options="tiposId"
                optionLabel="U_ACS_TpoIdentf"
                optionValue="U_ACS_TpoIdentf"
              />
              <AutoComplete
                class="w-8/12"
                inputClass="w-full"
                v-model="filtros.U_PHR_NumDcto"
                :suggestions="listadoPacientes"
                @complete="buscarPaciente"
                field="name"
              />
            </div>
          </div>
          <div class="w-full">
            <label class="text-xs text-gray-600" for="modalidad">Estado</label>
            <Dropdown
              panelClass="text-xs"
              class="w-full rounded-md"
              v-model="filtros.DocStatus"
              :options="estados"
              optionLabel="name"
              optionValue="value"
              id="estado"
              :showClear="true">
            </Dropdown>
          </div>
          <div class="flex items-end gap-2">
            <Button
              @click="listarOrdenes"
              size="small"
              class="rounded-md w-full"
              label="Buscar"
              icon="pi pi-search"
              :pt="{
                root: { style: 'height: 2.4rem' }
              }"
            />
            <Button
              @click="limpiarFiltros"
              size="small"
              severity="secondary"
              class="rounded-md w-full"
              label="Limpiar"
              :pt="{
                root: { style: 'height: 2.4rem' }
              }"
            />
          </div>
          <div class="flex items-end gap-2 justify-end">
            <Button @click="exportarExcel" icon="pi pi-file-excel" label="Exportar ovs" size="small" class="p-button-success" />
          </div>
        </div>
        <div class="w-full my-6">
          <DataTable class="p-datatable-sm text-xs" :value="ordenes.rows" responsiveLayout="scroll" selectionMode="single">
            <Column header="No. Orden">
              <template #body="{data}">
                <div class="text-center pt-3 idPadre">
                  <p class="text-blue-600">{{ data.Id }}</p>
                  <div class="flex gap-x-4 idHijo text-white transition ease-in-out duration-500">
                    <button @click="$router.push({ name: 'ut-segupharma.ventas.ov.ver', params: {id: data.Id} })" class=" underline italic">Ver</button>
                    <button v-if="data.DocStatus === 0 && $can('ut-segupharma.ventas.ov.editar')" @click="editarOv(data.Id)" class="underline italic">Editar</button>
                  </div>
                </div>
              </template>
            </Column>
            <Column field="FechaContabilizacion" header="Fecha Cont./Doc">
              <template #body="{data}">
                  {{ dayjs(data.FechaContabilizacion).format('YYYY-MM-DD') }}
              </template>
            </Column>
            <Column field="CardName" header="Cliente"></Column>
            <Column field="U_PHR_NumDcto" header="Documento paciente">
              <template #body="{data}">
                  {{ data.U_ACS_TpoIdentf + ' - ' + data.U_PHR_NumDcto }}
              </template>
            </Column>
            <Column field="U_ACS_NmbPct" header="Nombre paciente"></Column>
            <Column header="Documento paciente">
              <template #body="{data}">
                  {{ data.U_ACS_TpoIdentf + ' - ' + data.U_PHR_NumDcto }}
              </template>
            </Column>
            <Column field="U_PHR_BodegaNombre" header="Bodega"></Column>
            <Column field="fullName" header="Creado por">
              <template #body="{data}">
                <p>{{ data.fullName ? data.fullName.toUpperCase() : '' }}</p>
              </template>
            </Column>
            <Column header="Estado">
              <template #body="{data}">
                <div class="p-2 rounded-md text-center" :class="data.DocStatus === 0 ? 'bg-green-300' : (data.DocStatus === 1 ? 'bg-blue-300' : 'bg-red-600 text-white')">
                  <p class="font-bold">
                    {{ data.DocStatus === 0 ? 'Abierto' : (data.DocStatus === 1 ? 'Cerrado' : 'Cancelado') }}
                  </p>
                </div>
              </template>
            </Column>
            <Column header="Acción">
              <template #body="{data}">
                <Button
                  @click='DescargarPdf(data.Id)'
                  severity="info"
                  v-tippy="{ content: 'Imprimir pdf' }"
                  rounded
                  :pt="{
                    root: { style: 'width: 2rem; height: 2rem' }
                  }"
                  icon="pi pi-file-pdf"
                />
              </template>
            </Column>
          </DataTable>
          <Paginator v-model:first="offset"
            :rows="limit"
            :totalRecords="parseInt(ordenes.count)"
            :rowsPerPageOptions="[2,10,20,30,100]"
            @page="onPage($event)"
          />
        </div>
      </div>
    </section>
</template>
<script>
  import dayjs from 'dayjs'
  import axios from 'axios'
  import Cookies from 'js-cookie'
  import OrdenService from '../../../services/orden.service'
  import ClientesService from '../../../services/clientes.service'
  import BodegasService from '../../../services/bodegas.service'
  import PacientesService from '../../../services/pacientes.service'
  import ModalidadContratoService from '../../../services/modalidad-contrato.service'
  import { onMounted, ref, computed } from 'vue'
  import { useRouter } from 'vue-router'
  export default {
    name: 'ListadoOrdenesVenta',
    setup () {
      // Servicios
      const _OrdenService = ref(new OrdenService())
      const _ClientesService = ref(new ClientesService())
      const _BodegasService = ref(new BodegasService())
      const _PacientesService = ref(new PacientesService())
      const _ModalidadService = ref(new ModalidadContratoService())
      const router = useRouter()
      // Referencias
      const ordenes = ref([])
      const listadoClientes = ref([])
      const bodegasFiltradas = ref([])
      const listadoPacientes = ref([])
      const listadoModalidades = ref([])
      const ordenesFiltradas = ref([])
      const ordenSeleccionada = ref()
      const tiposId = ref([])
      const limit = ref(10)
      const offset = ref(0)
      const filtros = ref({
        CardCode: null,
        fecha_inicio: dayjs().format('YYYY-MM-DD'),
        fecha_fin: dayjs().format('YYYY-MM-DD'),
        Id: null,
        U_PHR_Bodega: null,
        U_ACS_TpoIdentf: null,
        U_PHR_NumDcto: null,
        DocStatus: null
      })
      const estados = ref([
        {
          name: 'Abierto',
          value: 0
        },
        {
          name: 'Cerrado',
          value: 1
        },
        {
          name: 'Cancelado',
          value: 2
        }
      ])
      const params = computed(() => {
        return {
          limit: limit.value,
          offset: offset.value,
          ...filtros.value
        }
      })
      // Stores
      // const ordenesStore = useOrdenesStore()
      // Metodos
      const onPage = ({ first, page, pageCount, rows }) => {
        limit.value = rows
        listarOrdenes(params.value)
      }
      const listarOrdenes = () => {
        agregarOv()
        ordenesFiltradas.value.length ? filtros.value.Id = JSON.stringify(ordenesFiltradas.value) : filtros.value.Id = null
        const object = {
          ...params.value
        }
        object.U_PHR_Bodega ? object.U_PHR_Bodega = object.U_PHR_Bodega.WhsCode : object.U_PHR_Bodega = null
        _OrdenService.value.listarOrdenes(object).then(({ data }) => {
          ordenes.value = data
        })
      }
      const editarOv = (id) => {
        router.push({ name: 'ut-segupharma.ventas.ov.editar', params: { id } })
      }
      const listarClientes = () => {
        _ClientesService.value.listarCLientes().then(({ data }) => {
          listadoClientes.value = data
        })
      }
      const listarTiposId = () => {
        _PacientesService.value.listarTiposId().then(({ data }) => {
          tiposId.value = data
        })
      }
      const buscarBodega = (event) => {
        _BodegasService.value.listarBodegas(event.query.toUpperCase()).then(({ data }) => {
          bodegasFiltradas.value = []
          for (const i of data) {
            const name = i.WhsCode + ' - ' + i.WhsName
            bodegasFiltradas.value.push({
              ...i,
              name
            })
          }
        })
      }
      const buscarPaciente = () => {

      }
      const listarModalidadesContrato = () => {
        _ModalidadService.value.listarModalidades().then(({ data }) => {
          listadoModalidades.value = data
        })
      }
      const agregarOv = () => {
        if (ordenSeleccionada.value) {
          for (const i of ordenSeleccionada.value.split(' ')) {
            if (ordenesFiltradas.value && ordenesFiltradas.value.length) {
              if (!ordenesFiltradas.value.some(a => a === i)) {
                i !== '' ? ordenesFiltradas.value.push(i) : ordenesFiltradas.value.push()
              }
            } else {
              if (i !== '') {
                i !== '' ? ordenesFiltradas.value.push(i) : ordenesFiltradas.value.push()
              }
            }
          }
        }
        ordenSeleccionada.value = ''
      }
      const validacionOrdenes = (e) => {
        if (/[^0-9\s]/.test(e.key)) {
          if (e.key === 'Backspace' || e.key === 'v' || e.key === 'Control') {
            if (e.key === 'v' && !e.ctrlKey) {
              e.preventDefault()
            }
          } else {
            e.preventDefault()
          }
        }
      }
      const eliminarOrden = (numeroOv) => {
        const index = ordenesFiltradas.value.findIndex(a => a === numeroOv)
        ordenesFiltradas.value.splice(index, 1)
      }
      const limpiarFiltros = () => {
        filtros.value = {
          CardCode: null,
          fecha_inicio: dayjs().format('YYYY-MM-DD'),
          fecha_fin: dayjs().format('YYYY-MM-DD'),
          Id: null,
          U_PHR_Bodega: null,
          U_ACS_TpoIdentf: null,
          U_PHR_NumDcto: null
        }
        ordenesFiltradas.value = []
        ordenSeleccionada.value = ''
        listarOrdenes()
      }

      const DescargarPdf = (id) => {
        const url = `${process.env.VUE_APP_MICROSERVICE_API_UT_SEGUPHARMA_ORDENES_VENTA}/ov/generar-pdf/${id}`
        const token = JSON.parse(Cookies.get('user')).token
        const res = axios.getUri({ url, params: { token: `Bearer ${token}` } })
        window.open(res, '_blank')
      }

      const exportarExcel = () => {
        const url = `${process.env.VUE_APP_MICROSERVICE_API_UT_SEGUPHARMA_ORDENES_VENTA}/ov/exportar-excel`
        const token = JSON.parse(Cookies.get('user')).token
        const res = axios.getUri({ url, params: { token: `Bearer ${token}`, fecha_inicio: filtros.value.fecha_inicio, fecha_fin: filtros.value.fecha_fin } })
        window.open(res, '_blank')
      }

      onMounted(() => {
        listarOrdenes()
        listarClientes()
        listarTiposId()
        listarModalidadesContrato()
        var menu = document.getElementsByClassName('side-nav')
        var namePage = document.getElementsByClassName('name-page')
        if (menu[0].classList.contains('hidden')) {
          menu[0].classList.remove('hidden')
        }
        if (namePage[0].classList.contains('hidden')) {
          namePage[0].classList.remove('hidden')
        }
      })
      return {
        filtros,
        limit,
        offset,
        ordenes,
        onPage,
        listarOrdenes,
        dayjs,
        listadoClientes,
        bodegasFiltradas,
        tiposId,
        buscarBodega,
        listadoPacientes,
        buscarPaciente,
        listadoModalidades,
        ordenSeleccionada,
        agregarOv,
        validacionOrdenes,
        eliminarOrden,
        ordenesFiltradas,
        limpiarFiltros,
        DescargarPdf,
        editarOv,
        estados,
        exportarExcel
      }
    }
  }
</script>
<style lang="scss">
  ::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189);
    border-radius: 9999px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  ::-webkit-input-placeholder { /* Edge */
    color: gray;
  }
  ::-webkit-scrollbar {
    width: 0.4rem;
  }
  .p-selectable-row:hover {
    .idHijo{
      color: blue
    }
  }
</style>
